<template>
  <Component
    :is="component"
    :to="to"
    :hreflang="to ? 'de' : null"
    class="text-theme hyphens-auto ring-theme group relative -mr-5 -ml-2 inline-block overflow-hidden rounded-[1em] pl-2 pr-5 focus-visible:ring-2"
  >
    <span
      class="inline-block translate-x-0 pr-[1.6em] leading-tight transition-transform group-hover:translate-x-[1.8em] group-focus-visible:translate-x-[1.8em]"
    >
      <IconArrowDash
        class="absolute top-[0.2em] left-[-0.5em] w-[1.1em] -translate-x-full opacity-0 transition-opacity group-hover:opacity-100 group-focus-visible:opacity-100"
      />
      <span class="relative">
        <span class="underline group-focus-visible:underline-offset-2">
          <slot
        /></span>
        <IconArrowDash
          class="absolute bottom-0 right-[-1.6em] w-[1.1em] transition-opacity group-hover:opacity-0 group-focus-visible:opacity-0"
        />
      </span>
    </span>
  </Component>
</template>

<script setup>
const props = defineProps({
  to: { type: [String, Object], default: null },
  is: { type: String, default: 'div' },
})

// determine whether to load NuxtLink or div
const component = computed(() => {
  if (props.to) {
    return resolveComponent('NuxtLink')
  }
  return props.is
})
</script>
